import React from 'react'
import { navigate } from '@reach/router';
import PropTypes from 'prop-types'


const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <span className="icon fa-diamond"></span>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Spectrum</h1>
                <p> One-to-One Business English  <br />
                    Attain clarity & Build confidence <br />
                    You define the goals.  <br />
                    We agree the topics.  <br />
                    you name a price.  <br />
                </p>
            </div>
        </div>
        <nav>
            <ul>
            <button 
               type="button"
               className="link-button" 
               onClick={() => {props.onOpenArticle('intro')}}>intro
               </button>
            <button 
               type="button"
               className="link-button" 
               onClick={() => {props.onOpenArticle('work')}}>process
            </button>
            <button 
               type="button"
               className="link-button" 
               onClick={() => {props.onOpenArticle('about')}}>about
            </button>
            <button 
               type="button"
               className="link-button"
               onClick={() => {props.onOpenArticle('contact')}}>contact
            </button>
            <button 
               className="link-button" 
               onClick={() => {navigate('https://content.englishonline.myglobalcloudservice.com/myblogposts')}}>blog
            </button>
            </ul>
        </nav>
    </header>
)


Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
