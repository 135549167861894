import React from 'react'
import PropTypes from 'prop-types'
import pic01 from '../images/pic01.jpg'


class Main extends React.Component {
  
  render() {
    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}>  Back  </div>
       
    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">The Intro</h2> 
            <p>I don’t know about you, but I found that I learn the fastest and
            have better retention through practical application. When
            learning another language, I found tackling the challenging
            aspects of a language using formal educational material unsuited
            to my learning preferences. What’s more, I find going through
            sentence structures and grammar tedious. However, I do enjoy and
            retain more of what I have learnt when I just get stuck in and
            have a good conversation. There is an exchange of
            ideas AND (as if by magic) I start to get the true sense of the
            words and their meaning in the current context.</p>
            <p>
              It is a fact that that the use of language varies depending on
              the setting in which it’s being used. This is particularly the
              case in the corporate world. One could easily agree that the
              language used daily in large multinationals is significantly
              different to the language that was probably used in your school
              text books.</p>
            <p>
              That’s what this service is about.</p>
            <p>
              I offer a 1-1 bespoke syllabus which we will build around your
              specific objectives leveraging situational discussions, based on
              real world scenarios, during which we'll explore current terminology.
              Our aim will be to fill in the gaps in your current understanding, support
              your personal development, and hopefully increase your
              confidence when dealing with tricky scenarios. It won’t be easy
              though it will be interesting, and I am positive that the
              process will most definitely get you to another level of clarity
              and understanding.</p>
          <span className="image main"><img src={pic01} alt="" /></span>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">The Method</h2>
          <p>
            This is intended for individuals with a good command of English who
            want to improve their skills as they pertain to 'business'
            English. The discussions will be aligned to your specific
            objectives using real world situational scenarios. The format will be video conference discussions during which we
            will cover the sceanrios and objectives that we have agreed.
            </p> 
            <p> 
             Without being too prescriptive we will cover scenarios that one
             might meet in a normal working day, for example handling negotiations, tackling change
             management, handling complaints, delivering performance reviews,
             and dealing with production issues. I suggest we cover topics of
             relevance to you and your specific areas of expertise. Once I
             understand your background, your defined goals, and we have
             agreed the scope of the learning activity we can begin to
             schedule sessions.</p> 
            <p>
             This is NOT for beginners or for anyone looking to brush up
             their ‘holiday English’. This is geared to professional people
             who want to get more ‘juice’ out of their daily interactions. I
             doubt it will be the cheapest you can find, but I am committed
             to offering an excellent return on your investment.
             </p>

          <span className="image main"><img src={pic01} alt="" /></span>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About You</h2>
          <p>
            Head over to the contact section and enter your details.
            Shortly afterwards you'll receive an email with a link to a very 
            short form.  Tell me about yourself, your experience of English 
            and areas that you want to improve. </p>
            <p>
            Tell me what you’re willing to offer in Euros per 60 minute
            session, your timezone (GMT+/-), and what time of day works for
            you.</p>
          <p>
            I’ll respond by email with an outline for an initial discussion
            and we can take it from there.</p>
            <p>
            How Much will it cost?</p>
          <p>
            You should assess the potential value of this type of
            situational learning and offer what you think this service is
            worth to you, not a penny more not a penny less. Who Knows,
            being a reasonable person who loves to give value, I may respond
            with a figure less than you offer.</p>
          <p>
            It’s a two-way street. If you don’t get a response, you haven’t
            made a reasonable offer.</p>

          <span className="image main"><img src={pic01} alt="" /></span>
          {close}
        </article>
                
        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">The Contact Page</h2>
          <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="givenname">Given Name</label>
              <input type="text" name="givenname" id="givenname" />
            </div>
            <div className="field half second">
              <label htmlFor="familyname">Family Name</label>
              <input type="text" name="familyname" id="familyname" />
            </div>
            <div className="field">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
           <ul className="icons">
            <li><a href="https://twitter.com/SpectrumEnglish" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a href="mailto:spectrum.englishonline@gmail.com" className="icon fa-envelope"><span className="label">email</span></a></li>
           </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main